import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
  useChasersContext,
  useChatDrawerContext,
  useFreezingLogs,
  useMapContext,
  useQuery,
} from 'hooks';
import { HOST, MAP_MAX_ZOOM } from 'config/common';

import { FetchableUpdatePanel, LiveStreamPanel } from 'components';
import { Sidebar, Map, Chat, LoginModal } from 'components/@HHMap';
import './styles.css';

const HHMap = () => {
  const checkFreezingMap = useFreezingLogs();

  useEffect(() => {
    checkFreezingMap();
  }, []);

  const params = useParams();
  const { station, approved } = params;

  const history = useHistory();
  const { isMobile, userID, userName, updateID } = useQuery();
  const { chatHidden } = useChatDrawerContext();
  const [isChatVisible, setChatVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setChatVisible(chatHidden);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const { viewport, setViewport, setStreamingChaserID, trackingChaserID, setTrackingChaserID } =
    useMapContext();
  const { chasers, setDefaultChasers, setApprovedChasers } = useChasersContext();

  useEffect(() => {
    fetch(`${HOST}/wp-json/wp/v2/options/default_chasers${station ? `/${station}` : ''}`)
      .then((r) => r.json())
      .then((data) => setDefaultChasers(data));
  }, []);

  useEffect(() => {
    if (approved) setApprovedChasers(approved.split(',').map((c) => parseInt(c)));
  }, [approved]);

  useEffect(() => {
    if (Object.keys(chasers).length && userID && userName && !updateID && !trackingChaserID) {
      if (chasers[userID]) {
        setTrackingChaserID(userID);
        setStreamingChaserID(chasers[userID]?.live ? userID : null);
        setMaxZoom();
      } else {
        history.push(`/?${isMobile ? 'mobile=true&' : ''}`);
      }
    }
  }, [chasers]);

  useEffect(() => {
    if (trackingChaserID && chasers[trackingChaserID]?.location && !updateID) {
      const userLocation = chasers[trackingChaserID]?.location;
      const { latitude, longitude } = viewport;
      if (latitude !== userLocation.lat || longitude !== userLocation.lng) {
        setViewport({
          ...viewport,
          longitude: chasers[trackingChaserID].location.lng,
          latitude: chasers[trackingChaserID].location.lat,
        });
        if (Boolean(userID && userName)) {
          history.push(`/?${isMobile ? 'mobile=true&' : ''}`);
        }
      }
    }
  }, [chasers, trackingChaserID]);

  const flyToChaser = async (id) => {
    if (!chasers.hasOwnProperty(id)) return;
    if (updateID) {
      history.push(`/?${isMobile ? 'mobile=true&' : ''}`);
    }
    await setMaxZoom();
    setTrackingChaserID(id);
    setStreamingChaserID(chasers[id]?.live ? id : null);
  };

  const setMaxZoom = () => {
    setViewport({
      ...viewport,
      zoom: Math.max(viewport.zoom, MAP_MAX_ZOOM),
    });
  };

  return (
    <div className="HHMap">
      <div className="Sidebar">
        <Sidebar flyToChaser={flyToChaser} />
        <LiveStreamPanel />
        <FetchableUpdatePanel />
      </div>
      <Map flyToChaser={flyToChaser} />
      {!isMobile && (
        <div className={`Chat ${isChatVisible && 'opacity-none'}`}>
          <Chat />
        </div>
      )}
      {!isMobile && (
        <div>
          <LoginModal />
        </div>
      )}
    </div>
  );
};

export default HHMap;
