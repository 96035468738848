import React, { useEffect, useState } from 'react';
import NavigationArrow from '../../Sidebar/NavigationArrow';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DotsSVG, PersonDotSVG, PersonSVG } from 'assets/icons';
import { useAuthContext, useChatDrawerContext, useLoginModal } from 'hooks';

const ChatHeader = (props) => {
  const { user, logout } = useAuthContext();
  const { toggleLoginModal } = useLoginModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTimestamp, setIsTimestamp] = useState(true);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const { chatOpen } = useChatDrawerContext();

  useEffect(() => {
    if (props.isNewMessage && !chatOpen) {
      setIsNewMessage(true);
    } else {
      setIsNewMessage(false);
    }
  }, [props.isNewMessage, chatOpen]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setTimeStamp = () => {
    const timestamp = props.setTimestamp();
    setIsTimestamp(timestamp);
    handleClose();
  };
  const onMenuClick = () => {
    user ? logout() : toggleLoginModal();
    handleClose();
  };

  return (
    <header className="chat">
      <NavigationArrow componentType={'chat'} />
      {
        <div className="header-wrapper">
          <div className="title-text">Live chat </div>
          <div className='person-img'>
            {isNewMessage ? <PersonDotSVG /> : <PersonSVG />}
          </div>
          <span className="user-counter">{props.users}</span>
          <div className="dots-wrapper">
            <Button
              id="demo-positioned-button"
              aria-controls={open ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <DotsSVG className="dots-svg" />
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{ disablePadding: true }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}>
              <MenuItem className="custom-menu-item" onClick={setTimeStamp}>
                {isTimestamp ? 'Enable timestamps' : 'Disable timestamps'}
              </MenuItem>
              <MenuItem className="custom-menu-item" onClick={onMenuClick}>
                {user ? 'Logout' : 'Login'}
              </MenuItem>
            </Menu>
          </div>
        </div>
      }
    </header>
  );
};

export default ChatHeader;
