import React, { useCallback } from 'react';
import classNames from "classnames";

import { useDrawer, useDrawerContext, useChatDrawerContext, useChatDrawer, useQuery } from "hooks";

import { NavigationArrowSVG } from "assets/icons";

const NavigationArrow = ({ componentType }) => {
  const { toggleDrawer } = useDrawer();
  const { toggleChatDrawer } = useChatDrawer();
  const { open } = useDrawerContext();
  const { chatOpen } = useChatDrawerContext();
  const { isMobile } = useQuery();

  const toggleSidebar = useCallback(() => {
    componentType ? toggleChatDrawer() : toggleDrawer();
  }, [componentType, toggleChatDrawer, toggleDrawer]);

  return <div className={classNames('Livestreams__arrow-wrapper', { 'mobile': isMobile })}>
    <span className='Livestreams__arrow-hint'>{(componentType ? chatOpen : open) ? 'Hide' : 'Show'}</span>
    <button className={componentType ? 'Livestreams-chat__arrow' : 'Livestreams__arrow'} onClick={toggleSidebar}>
      <NavigationArrowSVG className={classNames(componentType ? 'navigation-arrow-chat' : 'navigation-arrow', { 'closed': (componentType ? !chatOpen : !open) })} />
    </button>
  </div>
}

export default NavigationArrow;
