import React, { useRef, useState, useEffect } from 'react';
import { Layer, Source } from "react-map-gl";
import classNames from "classnames";
import moment from 'moment';

import { HOST, IS_WEATHER_INTEGRATION_ENABLED as isWeatherEnabledInitially } from 'config/common';
import { useChatDrawerContext, useDrawerContext, useQuery } from "hooks";

import { WeatherRadarSVG } from "assets/icons";

const WeatherLayer = ({ mapRef }) => {
  const { open } = useDrawerContext();
  const { chatOpen } = useChatDrawerContext();
  const { isMobile } = useQuery();



  const isWeatherIntegrationEnabled =
    isWeatherEnabledInitially && localStorage.getItem('isWeatherEnabled') ?
      localStorage.getItem('isWeatherEnabled') === 'true' : isWeatherEnabledInitially;

  const [weatherVisibility, setWeatherVisibility] = useState(isWeatherIntegrationEnabled);
  const weatherVisibilityRef = useRef(weatherVisibility);
  weatherVisibilityRef.current = weatherVisibility;

  const [weatherUpdatedTime, setWeatherUpdatedTime] = useState(null);

  const [latestTimeSlice, setLatestTimeSlice] = useState('');

  const getWeatherRadar = async (isTimeout = false) => {
    if (weatherVisibilityRef.current) {
      try {
        await fetch(
          `${HOST}/api/weather/timestamp/`, {
            method: 'GET',
            headers: {
              'apikey': '7B5zIqmRGXmrJTFmKa99vcit'
            },
          })
          .then(res => res.json())
          .then(latestTimestamp => {
            if (mapRef.current.getMap().getLayer("radar")) {
              mapRef.current.getMap().removeLayer('radar');
            }
            if (mapRef.current.getMap().getSource("twcRadar")) {
              mapRef.current.getMap().removeSource('twcRadar');
            }
            setLatestTimeSlice(latestTimestamp.seriesInfo.radar.series[0].ts);
          });
        setWeatherUpdatedTime(new Date());
      } catch (err) {
        console.log('err', err);
      }
    }
    if (isTimeout) {
      getWeatherRadarTimeout();
    }
  };

  const getWeatherRadarTimeout = () =>
    setTimeout(async () =>
      await getWeatherRadar(true), 1000 * 60 * 5);

  useEffect(async () => {
    await getWeatherRadar();
  }, [weatherVisibility]);

  useEffect(() => {
    getWeatherRadarTimeout();
  }, []);

  const toggleWeather = () => {
    localStorage.setItem('isWeatherEnabled', JSON.stringify(!weatherVisibility));
    setWeatherVisibility(!weatherVisibility)
  };

  const mapboxLogo = document.getElementsByClassName('mapboxgl-ctrl-logo')[0];
  const mapboxCtrl = document.getElementsByClassName('mapboxgl-ctrl mapboxgl-ctrl-group')[0];
  const mapboxTime = document.getElementsByClassName('time-panel')[0];

  useEffect(() => {
    if (mapboxLogo) {
      open ? mapboxLogo.classList.remove('collapsed') : mapboxLogo.classList.add('collapsed');
    }
    if (mapboxCtrl) {
      chatOpen ? mapboxCtrl.classList.remove('collapsed') : mapboxCtrl.classList.add('collapsed');
    }
    if (mapboxCtrl) {
      if (isMobile) mapboxCtrl.classList.remove('collapsed')
      isMobile ? mapboxCtrl.classList.add('mobile') : mapboxCtrl.classList.remove('mobile');
    }
    if (mapboxTime) {
      chatOpen ? mapboxTime.classList.remove('collapsed') : mapboxTime.classList.add('collapsed');
    }
  }, [open, mapboxLogo, chatOpen, mapboxCtrl, mapboxTime, isMobile]);

  return <>
    {weatherVisibility && latestTimeSlice &&
      <Source
        id="twcRadar"
        type="raster"
        tileSize={256}
        tiles={[`${HOST}/api/weather/tile?ts=${latestTimeSlice}&xyz={x}:{y}:{z}`]}
      >
        <Layer
          id="radar"
          type="raster"
          source='twcRadar'
          paint={{"raster-opacity": 0.5}}
        />
      </Source>
    }
    {isWeatherEnabledInitially &&
      <button
        className={classNames('weather-radar_btn', {
          'disabled': !weatherVisibility,
          'collapsed': !open
        })}
        onClick={() => toggleWeather()}
      >
        <WeatherRadarSVG />
      </button>
    }
    {weatherVisibility && weatherUpdatedTime &&
      <p className={classNames('weather-radar_text', {'collapsed': !open})}>
        Weather updated: &nbsp;
        {moment(weatherUpdatedTime.toISOString()).format( "D MMM")}, &nbsp;
        {weatherUpdatedTime.toISOString().substr(11, 5)}Z
      </p>
    }
  </>
}

export default WeatherLayer;
