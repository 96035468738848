import React from 'react';
import { Source, Layer } from 'react-map-gl';

import { useQuery, useMapContext } from "hooks";

const ChasePath = ({ id, route }) => {
  const { userID, updateID } = useQuery();
  const { trackingChaserID, streamingChaserID } = useMapContext();

  const geoJSON = () =>
    ({
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: route,
          },
        },
      ],
    });

  return (
    <Source type="geojson" data={geoJSON()}>
      <Layer
        {...{
          id: `line-animation-base-${id}`,
          type: 'line',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#192337',
            'line-width': 6,
            'line-opacity': 1,
          },
        }}
      />
      <Layer
        {...{
          id: `line-animation-${id}`,
          type: 'line',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-width': 4,
            'line-opacity': 1,
            'line-color':
              updateID && userID === id || trackingChaserID === id || streamingChaserID === id ? '#64BEFF' : '#0071C2',
          },
        }}
      />
    </Source>
  );
}

export default ChasePath;
