import { useLoginModalContext } from 'hooks';

const useLoginModal = () => {
  const context = useLoginModalContext();

  return {
    toggleLoginModal: () => {
      context.setOpen(!context.modalOpen);
      localStorage.setItem('isLoginModal', JSON.stringify(!context.modalOpen));
    }
  };
};

export default useLoginModal;
