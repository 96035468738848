import { useState } from 'react';

const useChatDrawerContextValues = () => {
  const [open, setOpen] = useState(localStorage.getItem('isHHChatOpened') === 'true');

  return {
    chatOpen: open,
    setOpen: setOpen,
  };
};

export default useChatDrawerContextValues;
