import React, { useState, useEffect } from 'react';
import classNames from "classnames";

import { useAuthContext, useLoginModal, useQuery } from "hooks";

import './styles.css';

const TimePanel = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const { isMobile } = useQuery();

  useEffect(() => {
    const intervalID = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000 * 60 * 0.5);

    return () => clearInterval(intervalID);
  }, [setCurrentTime]);

  return (
    <div className={classNames('time-panel', { 'mobile': isMobile })}>
      <div className="time-panel__time">
        <time>{currentTime.toISOString().substr(11, 5)}Z</time>
      </div>
    </div>
  );
}

export default TimePanel;
