import { useEffect, useRef, useState } from 'react';
import useWebSocket from "react-use-websocket";
import moment from "moment/moment";

import { SOCKET_URL } from "config/common";

const useChasersContextValues = () => {
  const {
    lastMessage
  } = useWebSocket(SOCKET_URL, {
    onOpen: () => console.log('connection is opened'),
    onClose: () => console.log('connection is closed'),
    shouldReconnect: () => true,
    retryOnError: () => true,
    reconnectInterval: 3000
  });

  const [chasers, setChasers] = useState({});
  const chasersRef = useRef(chasers);
  chasersRef.current = chasers;

  const [lastSocketMessageTime, setLastSocketMessageTime] = useState(null);
  const lastSocketMessageTimeRef = useRef(lastSocketMessageTime);
  lastSocketMessageTimeRef.current = lastSocketMessageTime;

  useEffect(() => {
    if(lastMessage?.data) {
      if (lastMessage.data !== 'ping') {
        setChasers(JSON.parse(lastMessage?.data));
      }
      setLastSocketMessageTime(moment(new Date()));
    }
  }, [lastMessage]);

  const [defaultChasers, setDefaultChasers] = useState([]);
  const [approvedChasers, setApprovedChasers] = useState([]);

  return {
    chasers,

    approvedChasers,
    setApprovedChasers,

    defaultChasers,
    setDefaultChasers,

    chasersRef,
    lastSocketMessageTimeRef
  };
};

export default useChasersContextValues;
