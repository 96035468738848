import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const CssTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        padding:'14px 16px'
    },
    '& .MuiOutlinedInput-root': {
      color: '#6C7794',
      fontFamily:'"Fira Sans", sans-serif',
      fontSize: '14px',
      caretColor: 'white',
      '& fieldset': {
        borderColor: '#6C7794',
      },
      '&:hover fieldset': {
        borderColor: '#6C7794',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #489BEE'
      },
    },
  });