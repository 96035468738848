import React from 'react';
import { useParams } from 'react-router-dom';

import NavigationArrow from "../NavigationArrow";
import { LogoSVG } from "assets/icons";

import { stations } from "./settings";

const Header = () => {
  const params = useParams();
  const { station } = params;

  return (
    <header>
      {station && stations[station] && stations[station]?.imgSrc ?
        <a href={stations[station].link} target="_blank">
          <img
            className="Livestreams__logo"
            src={stations[station].imgSrc}
            alt={stations[station].imgAlt}
            height={stations[station].imgHeight}
          />
        </a>
        :
        <a href="https://highwaysandhailstones.com/" target="_blank">
          <LogoSVG className="Livestreams__logo" />
        </a>
      }
      <NavigationArrow />
    </header>
  );
}

export default Header;
