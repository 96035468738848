import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ChatHeader from './ChatHeader';
import { useAuthContext, useChatDrawer, useChatDrawerContext, useQuery } from 'hooks';
import { StreamChat } from 'stream-chat';
import { CustomMessageInput } from './components/CustomMessageInput';
import { MessageListNotificationsCustom } from './components/MessageListNotificationsCustom';
import { MessagDeleted } from './components/MessageDeleted';
import { Disconnect } from './components/Disconnect';
import { HOST } from 'config/common';
import {
  Chat as StreamChatWrapper,
  Channel,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from 'stream-chat-react';
import './styles/style.scss';
import './styles/CustomMessage.scss';
import './styles/CustomMessageInput.scss';
import { CustomSystemMessage } from './components/CustomSystemMessage';

const Chat = () => {
  const { isMobile } = useQuery();
  const { chatOpen } = useChatDrawerContext();
  const { setHideChat, chatHidden } = useChatDrawer();
  const [channel, setChannel] = useState(null);
  const [userCount, setUserCount] = useState(null);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [isTimestamp, setTimestamp] = useState(true);
  const [isDisconnect, setIsDisconnect] = useState(false);
  const [chatClient] = useState(new StreamChat(`${process.env.REACT_APP_STREAM_CHAT_KEY}`));
  const isAnon = useRef(null);

  const { user } = useAuthContext();

  useEffect(async () => {
    try {
      const chats = await fetch(`${HOST}/wp-json/wp/v2/channels`);
      const response = await chats.json();
      let channelId = '';
      if(response.length) channelId = response[0].channel_id;
      if (!chatClient || !channelId) return;

      if (user) {
        let parsedUser = null;
        try {
          if (typeof user === 'object') {
            parsedUser = user;
          } else parsedUser = JSON.parse(user);
        } catch (error) {
          console.log(error);
        }
        if (isAnon.current) {
          await channel.stopWatching();
          setChannel(null);
          await chatClient.disconnectUser();
          isAnon.current = false;
        }
        if (parsedUser && parsedUser.user_id) {
          await chatClient.connectUser(
            {
              id: parsedUser.user_id.toString(),
              name: parsedUser.user_display_name,
              image: parsedUser.profile_picture,
            },
            parsedUser.meta.stream_io_chat_token
          );
          const channel = chatClient.channel('livestream', channelId);
          await channel.watch();
          setChannel(channel);
        }
      } else {
        if (!isAnon.current && !localStorage.getItem('userHh')) {
          isAnon.current = true;
          await chatClient.connectAnonymousUser();
        }
      }
      if (chatClient.user) {
        const channel = chatClient.channel('livestream', channelId);
        await channel.watch();
        setChannel(channel);
      }
    } catch (err) {
      console.error(err);
      setHideChat(true);
    }
  }, [user, chatHidden]);

  useEffect(() => {
    setTimeout(() => {
      if (channel?.state) {
        setUserCount(channel.state.watcher_count);
      }
    }, 500);
  }, [channel]);

  useEffect(() => {
    if (chatOpen) {
      setIsNewMessage(false);
    }
  }, [chatOpen]);

  const timestamp = () => {
    setTimestamp(!isTimestamp);
    return !isTimestamp;
  };

  chatClient.on('connection.changed', (e) => {
    if (e.online) {
      setIsDisconnect(false);
    } else {
      setIsDisconnect(true);
    }
  });
  chatClient.on('user.watching.start', (e) => {
    setUserCount(e.watcher_count);
  });
  chatClient.on('user.watching.stop', (e) => {
    setUserCount(e.watcher_count);
  });
  chatClient.on('health.check', (e) => {
    setUserCount(channel?.state?.watcher_count);
  });
  chatClient.on('message.new', (e) => {
    setIsNewMessage(true);
  });
  chatClient.on('channel.updated', (e) => {
    setHideChat(e?.channel?.disabled);
  });

  if (!chatClient.user && !channel) return null;
  return (
    <div className={isTimestamp ? 'timestamp' : ''}>
      <div
        className={classNames('Livestreams', 'Chat', {
          'Livestreams--hidden': !chatOpen,
          hidden: chatHidden,
          mobile: isMobile,
        })}>
        <ChatHeader
          users={userCount}
          isNewMessage={isNewMessage}
          setIsNewMessage={setIsNewMessage}
          setTimestamp={timestamp}
        />
        <div className="chat-wrapper">
          <StreamChatWrapper client={chatClient}>
            <Channel
              channel={channel}
              MessageSystem={CustomSystemMessage}
              autoCompleteTriggerSettings={() => ({})}
              Input={() => <CustomMessageInput isDisconnect={isDisconnect} />}
              MessageDeleted={MessagDeleted}
              MessageListNotifications={MessageListNotificationsCustom}>
              <Disconnect isDisconnect={isDisconnect} />
              <Window>
                <MessageList
                  hideDeletedMessages={false}
                  additionalMessageInputProps={{ closeEmojiPickerOnClick: true }}
                  disableDateSeparator
                  calendar={false}
                  noGroupByUser={true}
                  messageActions={['react', 'flag', 'mute', 'delete']}
                />

                <MessageInput additionalTextareaProps={{ placeholder: 'Send a message...' }} />
              </Window>
              <Thread />
            </Channel>
          </StreamChatWrapper>
        </div>
      </div>
    </div>
  );
};

export default Chat;
