const useUser = () => {

  const addUser = (user) => {
    localStorage.setItem("userHh", JSON.stringify(user));
  };

  const removeUser = () => {
    localStorage.removeItem("userHh", "");
  };

  return { addUser, removeUser };
};

export default useUser;