import React, { useEffect, useMemo, useRef, useState } from 'react';
import map from "lodash/map";
import { MAPBOX_API_ACCESS_TOKEN } from "config/common";

import sortBy from "lodash/sortBy";
import classNames from "classnames";

import { useChasersContext, useDrawerContext, useMapContext, useQuery } from "hooks";

import { Avatar } from 'components';

const ActiveChasers = ({ flyToChaser }) => {
  const { userID, updateID } = useQuery();
  const { open } = useDrawerContext();
  const { chasers, defaultChasers, approvedChasers } = useChasersContext();
  const { trackingChaserID, streamingChaserID } = useMapContext();
  const [addresses, setAddresses] = useState({});
  const intervalRef = useRef(null);
  const chasersRef = useRef(null);
  const initialChasersUpdareRef = useRef(false);
  chasersRef.current = chasers;

  const getPlaceName = (async () => {
    const chasers = chasersRef.current;
    if (!Object.keys(chasers).length) return;
    try {
      let newAddresses = { ...addresses };
      for (let id of Object.keys(chasers)) {
        newAddresses[id] = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${chasers[id].location.lng},${chasers[id].location.lat}.json?types=place&access_token=${MAPBOX_API_ACCESS_TOKEN}`
        )
          .then(r => r.json())
          .then(d => {
            return d.features[0]?.place_name
              .split(', ')
              .slice(0, 2)
              .join(', ');
          });

      }
      setAddresses(newAddresses);
    } catch (error) {
      console.log(error);
    }
  });

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      getPlaceName();
    }, 1800000);
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (Object.keys(chasers).length && !initialChasersUpdareRef.current) {
      getPlaceName();
      initialChasersUpdareRef.current = true;
    }
  }, [chasers]);

  const sortedChasingList = useMemo(() => {
    const filteredApproved = chasers && Object.keys(chasers).filter((id) =>
      approvedChasers.length
        ? approvedChasers.includes(parseInt(id))
        : true
    ) || [];

    const chasingChasers = filteredApproved.map(id => {
      let chaser = chasers[id];

      if (
        chaser.hasOwnProperty('location') &&
        chaser.hasOwnProperty('name')
      ) {
        return {
          id,
          ...chaser,
          address: addresses[id]
        }
      }
    });

    const defaultChasersID = map(defaultChasers, 'id');

    const defaultLiveList = chasingChasers.filter(c => c.live && defaultChasersID.includes(c.id));
    const notDefaultLiveList = chasingChasers.filter(c => c.live && !defaultChasersID.includes(c.id));

    const defaultChasingList = chasingChasers.filter(c => !c.live && defaultChasersID.includes(c.id));
    const notDefaultChasingList = chasingChasers.filter(c => !c.live && !defaultChasersID.includes(c.id));

    return [
      ...sortBy(defaultLiveList, c => defaultChasersID.indexOf(c.id)),
      ...sortBy(notDefaultLiveList, 'name'),
      ...sortBy(defaultChasingList, c => defaultChasersID.indexOf(c.id)),
      ...sortBy(notDefaultChasingList, 'name')
    ];
  }, [chasers, addresses]);

  if (!sortedChasingList.length) return null;

  return (
    <div className='Livestreams-list'>
      <h4 className='Livestreams__title'>Chasing</h4>
      {sortedChasingList.map(chaser => {
        if (chaser?.name) {
          return (
            <div
              key={chaser.id}
              className="Live__stream"
              onClick={() => flyToChaser(chaser.id)}
            >
              <div className={classNames('Live__chaser', {
                'Live__chaser--live': chaser.live,
                'Live__chaser--tracking':
                  chaser.id === trackingChaserID || chaser.id === streamingChaserID || updateID && chaser.id === userID
              })}>
                <Avatar name={chaser.name} avatarUrl={chaser.avatar_url} />
                {open && (
                  <div>
                    <p>{chaser.name}</p>
                    <p>{chaser.address}</p>
                  </div>
                )}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

export default ActiveChasers;
