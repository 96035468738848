import useUser from "./useUser";
import { useEffect } from "react";
import { useState } from "react";

const useAuthContextValues = () => {
  const { addUser, removeUser } = useUser();
  const [user, setUser] = useState('');
  useEffect(() => {
    setUser(localStorage.getItem("userHh"));
  }, []);
  const login = (user) => {
    addUser(user);
    setUser(user);
  };

  const logout = () => {
    setUser(null);
    removeUser();
  };

  return { user, login, logout };
};

export default useAuthContextValues;