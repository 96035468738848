import React from 'react';
import { Link } from 'react-router-dom';
import classNames from "classnames";

import { useDrawerContext } from "hooks";

import { LogoAmpersandSVG, AppStoreSVG, AppStoreIconSVG, GooglePlaySVG, GooglePlayIconSVG, StarSVG } from "assets/icons";
import './styles.css';

const OS = {
  iOS: {
    link: "//apps.apple.com/ua/app/highways-hailstones/id1562171693",
    icon: <AppStoreIconSVG width={40} height={40} />
  },
  Android: {
    link: "//play.google.com/store/apps/details?id=com.mlucolab.hh&pli=1",
    icon: <GooglePlayIconSVG width={32} height={32} />
  }
}

const Banner = ({ isVisible }) => {
  const { open } = useDrawerContext();

  if (!isVisible) return null;

  const getMobileOS = () => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return "Android"
    }
    else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      return "iOS"
    }
    return "Other"
  }

  const currentOS = getMobileOS();

  const isAndroidOS = getMobileOS() === "Android";
  const isIOS = getMobileOS() === "iOS";
  const isOtherOS = getMobileOS() === "Other";

  const isMobile = isAndroidOS || isIOS;

  return open ?
    <aside className={classNames('banner', {'mobile': isMobile})}>
      <div className="banner_header">
        <LogoAmpersandSVG className='logo' />
        <div>
          <h1>Highways & Hailstones</h1>
          {Array(5).fill(0).map((e,i) => <StarSVG key={i} />)}
        </div>
      </div>
      <p className="banner_text">Broadcast Your Live Storm Chase</p>
      {(isMobile && (isAndroidOS || isIOS)) &&
        <Link className="link--get-app" to={OS[currentOS].link} target="_blank">
          Get App
        </Link>
      }
      {(!isMobile || isOtherOS) &&
        <div className="banner_app-buttons">
          <Link to={"//apps.apple.com/ua/app/highways-hailstones/id1562171693"} target="_blank">
            <AppStoreSVG width={86} height={28} />
          </Link>
          <Link to={"//play.google.com/store/apps/details?id=com.mlucolab.hh&pli=1"} target="_blank">
            <GooglePlaySVG width={97} height={28} />
          </Link>
        </div>
      }
    </aside>
    :
    <aside className={classNames('banner collapsed', {'mobile': isMobile})}>
      {(isMobile && (isAndroidOS || isIOS)) &&
        <Link to={OS[currentOS].link} target="_blank">
          {OS[currentOS].icon}
        </Link>
      }
      {(!isMobile || (isMobile && isOtherOS)) &&
        <>
          <Link to={"//apps.apple.com/ua/app/highways-hailstones/id1562171693"} target="_blank">
            <AppStoreIconSVG width={40} height={40} />
          </Link>
          <Link to={"//play.google.com/store/apps/details?id=com.mlucolab.hh&pli=1"} target="_blank">
            <GooglePlayIconSVG width={32} height={32} />
          </Link>
        </>
      }
    </aside>;
}

export default Banner;
