import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from "react-router-dom";
import { Marker } from 'react-map-gl';
import classNames from "classnames";

import { useQuery, useMapContext } from "hooks";

import { PinSVG, PinTextSVG, PlaySVG } from "assets/icons";

const Update = ({
                  chaserID,
                  chaserName,
                  update,
                  location,
                  handleSetUpdate
}) => {
  const history = useHistory();
  const { updateID, isMobile } = useQuery();
  const isReplay = useRouteMatch('/replay');

  const { streamingChaserID, setStreamingChaserID, shouldCenterUpdate, setShouldCenterUpdate } = useMapContext();

  const initialType = update.type;
  let type = initialType === 'image' ? 'media' : initialType;

  const url = `${process.env.REACT_APP_HOST}/wp-json/wp/v2/${type}/${update.id}`;
  const [currentUpdate, setCurrentUpdate] = useState(false);

  useEffect(() => {
    if (!update.hasOwnProperty('meta')) {
      if (type === 'media' || type === 'text' || (type === 'video' && update.ready)) {
        fetch(url)
          .then((r) => r.json())
          .then((data) => {
            setCurrentUpdate(data);
          });
      }
    } else {
      setCurrentUpdate(update);
    }
  }, [type, update.ready]);

  const onHandleSetUpdate = () => {
    if (streamingChaserID) {
      setStreamingChaserID(false);
    }
    if (isReplay) {
      handleSetUpdate({
        update,
        type,
        id: update.id,
        chaser_id: chaserID,
        skip_fetch: true,
      })
    } else {
      if (shouldCenterUpdate) {
        setShouldCenterUpdate(false);
      }
      history.push(`/?${isMobile ? 'mobile=true&' : ''}user_id=${chaserID}&user_name=${chaserName.replace(' ', '')}&update=${update.id}`, {userId: chaserID});
    }
  }

  const thumbnail = useMemo(() => {
    if (initialType === 'video' && currentUpdate) return `${currentUpdate.meta.preview}?width=64`;
    if ((initialType === 'image' || initialType === 'attachment') && update.media_type !== 'file') {
      if (currentUpdate?.media_details?.sizes?.thumbnail) {
        return currentUpdate.media_details.sizes.thumbnail.source_url;
      } else {
        return currentUpdate.source_url;
      }
    }
  }, [initialType, currentUpdate, update])

  if (!currentUpdate) return null;

  return <Marker
          latitude={location.lat}
          longitude={location.lng}
          offsetTop={-40}
          offsetLeft={-16}
          onClick={onHandleSetUpdate}
        >
          <div className={`HHMarker HHMarker--update HH--update--${initialType}`}>
            <div className={classNames('Update__pin', { 'Update__pin--selected': Number(updateID) === update.id })}>
              {initialType === 'text' ? (
                <PinTextSVG />
              ) : (
                <>
                  <PinSVG />
                  {thumbnail && <img src={thumbnail.toString()} height="28" width="28" alt=""/>}
                  {initialType === 'video' && <PlaySVG className="Update__pin__video" />}
                </>
              )}
            </div>
          </div>
        </Marker>
  ;
}

export default Update;
