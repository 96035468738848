import React, { useEffect, useRef } from 'react';
import mux from 'mux-embed';
import Hls from 'hls.js';

import { MUX_DATA } from "config/common";

import StreamingInfo from "./StreamingInfo";

const Video = props => {
  const video = useRef(null);

  useEffect(() => {
    const playbackId = props.playback_id;
    const url = props.playback_id ? 'https://stream.mux.com/' + playbackId + '.m3u8' : props.url;

    let hls = null;
    if (video.current.canPlayType('application/vnd.apple.mpegurl')) {
      video.current.src = url;
      video.current.addEventListener('loadedmetadata', function () {
        video.current.play();
      });
    } else if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(video.current);
/*      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.current.play();
      });*/
    }

    const videoRef = video.current;

    return () => {
      videoRef.pause();
      if (hls) {
        hls.stopLoad();
        hls.destroy();
      }
    };
  }, [props.playback_id, props.url]);

  useEffect(() => {
    if (video.current) {
      const initTime = Date.now();

      /*const video_id = () => {
        if (props.playback_id) return props.playback_id;
        if (props.url) {
          const s = props.url.split('/');
          if (s && s.length) return s[s.length - 1]?.split('.')[0];
        }
        return '';
      };*/

      mux.monitor("#video-stream", {
        debug: false, data: {
          env_key: MUX_DATA,

          // Player Metadata
          player_name: 'HH Live', // ex: 'My Main Player'
          player_init_time: initTime,

          // Video Metadata
          video_id: props.video_id, // ex: 'abcd123'
          video_title: props.video_title, // video_series: '',
          video_stream_type: props.playback_id ? 'live' : 'on-demand', // 'live' or 'on-demand'
        },
      });
    }
  }, [video, props.playback_id, props.url, props.video_title, props.video_id]);

  return (<div className="Video">
    <video
      id="video-stream"
      controls
      preload="none"
      ref={video}
      muted={props.muted}
      playsInline
      autoPlay
      controlsList="nodownload"
      disablePictureInPicture
    />
    {props.isLivestream && <StreamingInfo />}
  </div>);
}

export default Video;
