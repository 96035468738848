import {useRef, useState} from "react";
import {HOST} from "config/common";
import moment from "moment/moment";

import { useChasersContext } from "hooks";

const useFreezingLogs = () => {
  const { chasersRef, lastSocketMessageTimeRef } = useChasersContext();

  const [areFreezingLogsSent, setAreFreezingLogsSent] = useState(false);
  const areFreezingLogsSentRef = useRef(areFreezingLogsSent);
  areFreezingLogsSentRef.current = areFreezingLogsSent;

  const sendLogsDueToFreezing = async () => {
    try {
      await fetch(`${HOST}/wp-json/wp/v2/livemap/logs`, {
        method: "POST",
        body: JSON.stringify({ active_chasers: Object.keys(chasersRef.current).length }),
      });
      setAreFreezingLogsSent(true);
    } catch (err) {
      console.log('err', err);
    }
  };

  const checkFreezingMap = () => setTimeout( async () => {
    if (lastSocketMessageTimeRef.current && !areFreezingLogsSentRef.current) {
      const now = moment(new Date());
      const duration = moment.duration(now.diff(lastSocketMessageTimeRef.current));
      const minutes = duration.asMinutes();

      if (minutes > 3) {
        await sendLogsDueToFreezing();
      }
    }
    checkFreezingMap();
  }, 120000);

  return checkFreezingMap;
};

export default useFreezingLogs;
