import React, { useRef } from "react";

const HLSDetails = ({ nicename }) => {
  const inputRef = useRef(null);

  return (
    <div className="Wall__hls">
      HLS:
      <input
        type="text"
        value={`${process.env.REACT_APP_HOST}/chasers/${nicename}.m3u8`}
        ref={inputRef}
      />
      <button
        onClick={() => {
          inputRef.current.select();
          document.execCommand('copy');
          inputRef.current.blur();
        }}
      >
        <img src="/assets/clipboard.svg" alt="Copy to Clipboard" />
        &nbsp;Copy
      </button>
    </div>
  );
};

export default HLSDetails;
