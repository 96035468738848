import { useState } from 'react';

import { DEFAULT_VIEWPORT } from "config/common";

const useMapContextValues = () => {
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT);
  const [bounds, setBounds] = useState(false);
  const [trackingChaserID, setTrackingChaserID] = useState('');
  const [streamingChaserID, setStreamingChaserID] = useState('');
  const [shouldCenterUpdate, setShouldCenterUpdate] = useState(true);

  return {
    viewport, setViewport,
    trackingChaserID, setTrackingChaserID,
    streamingChaserID, setStreamingChaserID,
    shouldCenterUpdate, setShouldCenterUpdate,
    bounds, setBounds
  };
};

export default useMapContextValues;
