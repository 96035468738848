import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ChasersProvider, DrawerProvider, ChatDrawerProvider, MapProvider, LoginModalProvider, AuthProvider } from 'context';
import { useDrawerContextValues, useChasersContextValues, useAuthContextValues, useChatDrawerContextValues, useMapContextValues, useLoginModalContextValues } from "hooks";

import HHReplay from 'pages/HHReplay';
import HHMap from 'pages/HHMap';
import HHWall from 'pages/HHWall';

import 'modern-normalize/modern-normalize.css';
import './App.css';

const App = () => {
  const drawerValues = useDrawerContextValues();
  const chatDrawerValues = useChatDrawerContextValues();
  const loginModalValues = useLoginModalContextValues();
  const mapValues = useMapContextValues();
  const chasersValues = useChasersContextValues();
  const authValues = useAuthContextValues();

  return (
    <AuthProvider value={authValues}>
      <LoginModalProvider value={loginModalValues}>
        <ChatDrawerProvider value={chatDrawerValues}>
          <DrawerProvider value={drawerValues}>
            <MapProvider value={mapValues}>
              <ChasersProvider value={chasersValues}>
                <Router>
                  <Switch>
                    <Route path="/embed/:station/map/:approved">
                      <HHMap />
                    </Route>

                    <Route path="/embed/:station/wall/:approved">
                      <HHWall />
                    </Route>

                    <Route path="/replay/:chase_id">
                      <HHReplay />
                    </Route>

                    <Route path="/">
                      <HHMap />
                    </Route>
                  </Switch>
                </Router>
              </ChasersProvider>
            </MapProvider>
          </DrawerProvider>
        </ChatDrawerProvider>
      </LoginModalProvider>
    </AuthProvider>
  )
}

export default App;
