import { useChatDrawerContext } from 'hooks';
import { useState } from 'react';

const useChatDrawer = () => {
  const context = useChatDrawerContext();
  const [isChatVisible, setChatVisible] = useState(false);
  return {
    toggleChatDrawer: () => {
      context.setOpen(!context.chatOpen);
      localStorage.setItem('isHHChatOpened', JSON.stringify(!context.chatOpen));
    },
    setHideChat: (hide) => {
      setChatVisible(hide);
      if (hide) {
        context.setOpen(false);
        localStorage.setItem('isHHChatOpened', false);
      } else {
        context.setOpen(true);
        localStorage.setItem('isHHChatOpened', true);
      }
    },
    chatHidden: isChatVisible,
  };
};

export default useChatDrawer;
