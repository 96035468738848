import { useState } from 'react';

const useDrawerContextValues = () => {
  const [open, setOpen] = useState(localStorage.getItem('isHHSidebarOpened') === 'true');

  return {
    open: open,
    setOpen: setOpen,
  };
};

export default useDrawerContextValues;
