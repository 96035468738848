import { useMemo } from 'react';

const Avatar = ({ name, avatarUrl }) => {
  const hashedHSL = useMemo(() => {
    let hash = 0;
    if (!name || name.length === 0) return hash;
    for (let i = 0; i < name.length; i++) {
      let code = parseInt(name[i], 36);
      if (Number.isInteger(code)) hash = hash + code;
    }
    return Math.abs(hash % 360);
  }, [name]);

  if (!name) return <img alt="" />;
  const [first, last] = name.split(' ');

  return avatarUrl ? (
    <img src={avatarUrl} alt={name} />
  ) : (
    <div
      className={`FakeAvatar Avatar__blank ${hashedHSL}`}
      style={{ background: `hsla(${hashedHSL}, 29%, 74%, 0.8)` }}
    >
      {first && first.length && first[0]}
      {last && last.length && last[0]}
    </div>
  );
}

export default Avatar;
