import { DisconnectSVG } from 'assets/icons';

export const Disconnect = ({ isDisconnect }) => {
  return (
    <>
      {isDisconnect && (
        <div className="notification-wrapper bottom-btn">
          <DisconnectSVG className="icon" />
          <div>
            <div>Chat disconnected</div>
            <div>Please wait while we try to reconnect you</div>
          </div>
        </div>
      )}
    </>
  );
};
