import React from 'react';

import { HOST } from "config/common";
import { useChasersContext, useDrawerContext } from 'hooks';

import { Avatar } from 'components';

const DefaultChasers = () => {
  const { open } = useDrawerContext();
  const { chasers, defaultChasers } = useChasersContext();

  if (defaultChasers.length === 0) return null;

  const filteredDefault = defaultChasers.filter(
    (c) => !Object.keys(chasers).includes(c.id)
  );

  if (!filteredDefault.length) return null;

  return (
    <>
      <h4 className='Livestreams__title'>Not Chasing</h4>
      {filteredDefault.map(offlineChaser => (
        <div key={offlineChaser.ID} className="Live__stream">
          <a
            className={`Live__chaser Live__chaser--offline`}
            href={`${HOST}/chasers/${offlineChaser.user_nicename}`}
            target="_blank"
            rel="noreferrer"
          >
            <Avatar name={offlineChaser.display_name} avatarUrl={offlineChaser.profile_picture?.sizes?.thumbnail}  />
            {open && (
              <div>
                <p>{offlineChaser.display_name}</p>
              </div>
            )}
          </a>
        </div>
      ))}
    </>
  );
}

export default DefaultChasers;
