import { useLocation } from "react-router-dom";

const useQuery = () => {
  const query = new URLSearchParams(useLocation().search);

  return {
    userID: query.get('user_id'),
    userName: query.get('user_name'),
    updateID: query.get('update'),
    isMobile: query.get('mobile') === 'true',
    isSignup: query.get('signup') === 'true',
    isTrack: query.get('track') !== 'false',
    areEvents: query.get('events') !== 'false',
  }
};

export default useQuery;
