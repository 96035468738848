import React, {useEffect, useState} from 'react';
import moment from 'moment';

import { useMapContext } from "hooks";
import { SOCKET_URL } from 'config/common';

const StreamingInfo = () => {
  const [views, setViews] = useState(0);

  const [streamStartedAt, setStreamStartedAt] = useState();
  const [streamingTime, setStreamingTime] = useState('');
  const { trackingChaserID } = useMapContext();

  useEffect(() => {
    const interval = setInterval(() => {
      if (streamStartedAt) {
        const startTime = moment(streamStartedAt);
        const currentTime = moment(new Date().getTime());
        const duration = moment.duration(currentTime.diff(startTime));

        const hours = Math.floor(duration.asHours());
        const hoursStr = hours > 0 ? ('0' + hours).slice(-2) + ':' : '';

        const minutes = Math.floor(duration.asMinutes()) - hours * 60;
        const minutesStr = ('0' + minutes).slice(-2) + ':';

        const seconds = Math.floor(duration.asSeconds()) - hours * 60 * 60 - minutes * 60;
        const secondsStr = ('0' + seconds).slice(-2);

        setStreamingTime(hoursStr + minutesStr + secondsStr);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [streamStartedAt]);

  useEffect(() => {
    const socket = new WebSocket(SOCKET_URL);

    socket.onmessage = (data) => {
      const socketData = JSON.parse(data.data);
      if (trackingChaserID && socketData[trackingChaserID]?.views) {
        setViews(socketData[trackingChaserID].views);
      }
      if (trackingChaserID && socketData[trackingChaserID]?.live_started_at) {
        setStreamStartedAt(socketData[trackingChaserID].live_started_at);
      }
    };

    return function cleanup() {
      socket.close();
    };
  }, [trackingChaserID]);

  return (<div className='video-info'>
    <span>{streamingTime}</span>
    <img width={21} src="/assets/view.svg" alt="Copy to Clipboard"/>
    <span>{views}</span>
  </div>);
}

export default StreamingInfo;
