import React from "react";

import { useChasersContext } from "hooks";

import { Chaser } from "components/@HHMap";

const ChasersList = ({ handleFlyToChaser }) => {
  const { chasers, approvedChasers } = useChasersContext();

  if (!(Object.keys(chasers).length > 0)) return null;

  return Object.keys(chasers)
    .filter(id => approvedChasers.length ? approvedChasers.includes(parseInt(id)) : true)
    .map(id => {
      const chaser = chasers[id];
      const shouldShowChaser = chaser.hasOwnProperty('location') && chaser.hasOwnProperty('name');
      return shouldShowChaser ?
        <Chaser
          key={id}
          id={id}
          {...chaser}
          handleFlyToChaser={handleFlyToChaser}
        />
        : null;
    });
}

export default ChasersList;
