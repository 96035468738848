import React from 'react';
import { useParams } from 'react-router-dom';
import classNames from "classnames";

import { useDrawerContext, useQuery } from 'hooks';

import Header from "./Header";
import Banner from "./Banner";
import ActiveChasers from "./ActiveChasers";
import DefaultChasers from "./DefaultChasers";

const Sidebar = ({ flyToChaser }) => {
  const { isSignup, isMobile } = useQuery();
  const params = useParams();
  const { station } = params;

  const { open } = useDrawerContext();

  return (
    <div className={classNames('Livestreams', { 'Livestreams--hidden': !open, 'mobile': isMobile })}>
      <Header />
      <Banner isVisible={!isMobile && (!station || isSignup)} />
      <div className='chasers-wrapper'>
        <ActiveChasers flyToChaser={flyToChaser} />
        <DefaultChasers />
      </div>
    </div>
  );
}

export default Sidebar;
