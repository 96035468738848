import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { useAuthContext, useLoginModal, useLoginModalContext, useQuery } from 'hooks';
import './style.scss';
import { CssTextField } from 'components/styled/CssTextField';
import { HOST } from 'config/common';
import hhLogo from 'assets/icons/hh-icon.png';

const LoginModal = () => {
  const { isMobile } = useQuery();

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { modalOpen } = useLoginModalContext();
  const { toggleLoginModal } = useLoginModal();

  const { user, login, logout } = useAuthContext();


  const loginUser = async (credentials) => {
    setIsError(false);
    setErrorMessage('');
    let formData = new FormData();
    let { username, password } = credentials;
    if (!username || !password) return;
    formData.append('username', username);
    formData.append('password', password);
    try {
      setLoading(true);
      
      const resp = await fetch(`${HOST}/wp-json/jwt-auth/v1/token`, {
        method: 'POST',
        body: formData
      });
      const response = await resp.json();
      if (response.token) {
        login(response);
        toggleLoginModal();
      }
      if (response.data && response.message) {
        setIsError(true);
        if (response.code && response.code.includes('incorrect_password')) {
          setErrorMessage('Incorrect password.');
        } else if (response.code && response.code.includes('invalid_username')) {
          setErrorMessage('Unknown email address. Check again or try your username.');
        } else
          setErrorMessage(response.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsError(true);
      setErrorMessage("Something went wrong.");
      console.log(error);
    }
  }

  const setToken = (userToken) => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });
    setToken(token);
  }

  const onToogleModal = (e) => {
    e.stopPropagation();
    toggleLoginModal();
  }

  return (
    <>
      {modalOpen && <div className="login-wrapper" onClick={onToogleModal}
      >
        <div className="login-inner" onClick={e => e.stopPropagation()}>
          <form onSubmit={handleSubmit}>
            <div className="modal-header">
              <img src={hhLogo} width={58} height={58} />
              <div className="modal-title">Log in to Highways & Hailstones</div>
            </div>
            
            <CssTextField className='custom-input' placeholder="Username" id="custom-css-outlined-input" onChange={e => setUserName(e.target.value)} margin="dense" />
            <CssTextField className='custom-input' placeholder="Password" id="custom-css-outlined-input" type='password' onChange={e => setPassword(e.target.value)} margin="dense" />

            <button className='submit-btn' type='submit'>{isLoading ? <Oval
              height={15}
              width={15}
              color="#fff"
              wrapperClass="spinner-wrapper"
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#b5b5b5"
              strokeWidth={6}
              strokeWidthSecondary={6}
            /> : 'Log In'}</button>
            {isError && <span className='auth-error'>{errorMessage}</span>}
            <a href={`${HOST}/join/`} className='signup' target="_blank" rel="noreferrer">Don’t have an account? Sign up</a>
          </form>
        </div>
      </div>}
    </>
  )
}

export default LoginModal;