import {
  ChatAutoComplete,
  EmojiIconLarge,
  EmojiPicker,
  useMessageInputContext,
} from 'stream-chat-react';

import '../styles/CustomMessageInput.scss';
import { useState } from 'react';
import { SendSVG } from 'assets/icons';
import { useAuthContext, useLoginModal } from 'hooks';

export const CustomMessageInput = (props) => {
  const [focus, setFocus] = useState(false);
  const { user } = useAuthContext();
  const { toggleLoginModal } = useLoginModal();

  const { closeEmojiPicker, emojiPickerIsOpen, handleEmojiKeyDown, handleSubmit, openEmojiPicker } =
    useMessageInputContext();

  const onFocus = () => {
    if (!user) {
      toggleLoginModal();
      return;
    }
    setFocus(true);
  };
  const onBlur = (e) => {
    if (e && e.type === 'blur') {
      setFocus(false);
    }
  };

  return (
    <div className="str-chat__message-input custom-input">
      <div className={`str-chat__message-input-inner ${focus ? 'bordered' : ''}`}>
        <div className="str-chat__message-textarea-container">
          <div className="str-chat__message-textarea-with-emoji-picker">
            <ChatAutoComplete onFocus={onFocus} onBlur={onBlur} />
            <span
              className="str-chat__input-flat-emojiselect"
              onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
              onKeyDown={handleEmojiKeyDown}
              role="button"
              tabIndex={0}>
              <EmojiIconLarge />
            </span>
            <div className="str-chat__message-textarea-emoji-picker">
              <div className="str-chat__message-textarea-emoji-picker-container">
                {!props.isDisconnect && <EmojiPicker />}
              </div>
            </div>
          </div>
        </div>
        <button
          className={`str-chat__send-button ${!focus ? 'disabled' : ''}`}
          onClick={handleSubmit}>
          <SendSVG />
        </button>
      </div>
    </div>
  );
};
