export const HOST = process.env.REACT_APP_HOST;
export const MUX_DATA = process.env.REACT_APP_MUX_DATA;
export const MAPBOX_API_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN;
export const IS_WEATHER_INTEGRATION_ENABLED = process.env.REACT_APP_IS_WEATHER_INTEGRATION_ENABLED === 'true';

export const SOCKET_URL = process.env.REACT_APP_WS.replace('http', 'ws');

export const DEFAULT_VIEWPORT = {
  latitude: 41,
  longitude: -96,
  zoom: 4,
  bearing: 0,
  pitch: 0
}

export const MAP_MAX_ZOOM = 9;
