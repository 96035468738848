import { useState } from 'react';

const useLoginModalContextValues = () => {
  const [open, setOpen] = useState(localStorage.getItem('isLoginModal') === 'true');

  return {
    modalOpen: open,
    setOpen: setOpen,
  };
};

export default useLoginModalContextValues;
