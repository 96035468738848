import { useEffect, useState } from 'react';
import { ArrowBottom, ShapeSVG } from 'assets/icons';
import '../styles/Notifications.scss';

export const MessageListNotificationsCustom = (props) => {
  const { scrollToBottom, notifications, hasNewMessages, isMessageListScrolledToBottom } = props;
  const [isNotif, setIsNotif] = useState(false);
  const [text, setText] = useState('');
  const [isFailure, setIsFailure] = useState(false);

  useEffect(() => {
    if (notifications?.length) {
      setIsNotif(true);
      setIsFailure(false);
      if (notifications[0].text.includes('flagged')) {
        setText('User successfully flagged');
      }
      if (notifications[0].text.includes('muted')) {
        setText('User successfully muted');
      }
      if (notifications[0].text.includes('unmuted')) {
        setText('User successfully unmuted');
      }
      if (notifications[0].text.includes('failure')) {
        setIsFailure(true);
      }
    } else {
      setIsNotif(false);
      setText('');
    }
  }, [notifications]);

  return (
    <>
      {isNotif && (
        <>
          {!isFailure && (
            <div className="notification-wrapper">
              <ShapeSVG className="icon" />
              <div className="inner-text">{text}</div>
            </div>
          )}
        </>
      )}
      {!isMessageListScrolledToBottom && (
        <div onClick={scrollToBottom} className="new-message-wrapper bottom-btn">
          <div className="new-message-inner">
            <div className="bottom-icon">
              <ArrowBottom />
            </div>
            {hasNewMessages && <div className="new-message-text">New messages</div>}
          </div>
        </div>
      )}
    </>
  );
};
