import React from 'react';

function formattedDuration(seconds) {
  const duration = new Date(seconds * 1000)
    .toISOString()
    .substr(11, 8)
    .split(':');

  return `${duration[0] > 0 ? `${duration[0]}h ` : ''} ${duration[1]}m`;
}

function DDDT(props) {
  return (
    <div>
      <dt>{props.name}</dt>
      <dd>{props.children}</dd>
    </div>
  );
}

function Panel(props) {
  var date = new Date(
    props.started.timestamp.toString().length < 11
      ? props.started.timestamp * 1000
      : props.started.timestamp
  );
  return (
    <div className="HHPanel">
      <dl>
        <DDDT
          name={`${date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}`}
        >
          <h2>Chase Log</h2>
        </DDDT>
        <DDDT name="Duration">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125C11.9354 18.125 13.7112 17.4493 15.1069 16.3198C15.2123 16.2345 15.3155 16.1467 15.4165 16.0563C15.5254 15.9588 15.6318 15.8583 15.7354 15.7551L16.9707 16.9946C16.845 17.1199 16.7159 17.2419 16.5835 17.3603C16.4744 17.458 16.363 17.5533 16.2496 17.6462L16.2077 17.6802C14.512 19.0523 12.3512 19.875 10 19.875C4.54619 19.875 0.125 15.4538 0.125 10C0.125 4.54619 4.54619 0.125 10 0.125C10.3042 0.125 10.6055 0.138785 10.9031 0.165803L10.7449 1.90864C10.4998 1.88639 10.2514 1.875 10 1.875Z"
              fill="#A2AAC1"
            />
            <path
              d="M13.7833 2.80751C13.3474 2.57773 12.8883 2.3865 12.4104 2.23827L12.9289 0.566822C13.5107 0.747288 14.0694 0.980027 14.5994 1.25947L13.7833 2.80751Z"
              fill="#A2AAC1"
            />
            <path
              d="M16.2474 4.80484C15.9294 4.42293 15.5771 4.0706 15.1952 3.75263L16.3149 2.40775C16.7785 2.79377 17.2062 3.22146 17.5923 3.68511L16.2474 4.80484Z"
              fill="#A2AAC1"
            />
            <path
              d="M17.7617 7.58958C17.6135 7.11168 17.4223 6.65256 17.1925 6.21672L18.7405 5.40058C19.02 5.93062 19.2527 6.48932 19.4332 7.07115L17.7617 7.58958Z"
              fill="#A2AAC1"
            />
            <path
              d="M18.125 10C18.125 9.74864 18.1136 9.50017 18.0914 9.25505L19.8342 9.09685C19.8612 9.3945 19.875 9.69575 19.875 10C19.875 10.3455 19.8572 10.6872 19.8225 11.0241L18.0817 10.8445C18.1103 10.5672 18.125 10.2854 18.125 10Z"
              fill="#A2AAC1"
            />
            <path
              d="M16.9305 14.2432C17.2232 13.7663 17.4678 13.2572 17.6579 12.7224L19.3068 13.3087C19.0754 13.9596 18.7777 14.5789 18.422 15.1585L16.9305 14.2432Z"
              fill="#A2AAC1"
            />
            <path
              d="M10.875 3.5V10.0097L14.6643 14.4306L13.3357 15.5694L9.125 10.657V3.5H10.875Z"
              fill="#A2AAC1"
            />
          </svg>

          {formattedDuration(props.duration)}
        </DDDT>
        <DDDT name="Distance">
          <svg
            width="21"
            height="17"
            viewBox="0 0 21 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.875 5.35787C4.9648 4.99237 5.75 3.96288 5.75 2.75C5.75 1.23122 4.51878 0 3 0C1.48122 0 0.25 1.23122 0.25 2.75C0.25 3.96288 1.0352 4.99237 2.125 5.35787L2.125 11.5C2.125 13.9162 4.08375 15.875 6.5 15.875C8.91625 15.875 10.875 13.9162 10.875 11.5V4.5C10.875 3.05025 12.0503 1.875 13.5 1.875C14.9497 1.875 16.125 3.05025 16.125 4.5V12.8875L14.6188 11.3813L13.3813 12.6187L17.0001 16.2375L20.6189 12.6187L19.3814 11.3813L17.875 12.8877V4.5C17.875 2.08376 15.9162 0.125 13.5 0.125C11.0838 0.125 9.125 2.08375 9.125 4.5V11.5C9.125 12.9497 7.94975 14.125 6.5 14.125C5.05025 14.125 3.875 12.9497 3.875 11.5L3.875 5.35787ZM3 3.75C3.55228 3.75 4 3.30228 4 2.75C4 2.19772 3.55228 1.75 3 1.75C2.44772 1.75 2 2.19772 2 2.75C2 3.30228 2.44772 3.75 3 3.75Z"
              fill="#A2AAC1"
            />
          </svg>
          {`${(props.distance / 1000).toFixed(1)} km`}
        </DDDT>
        <DDDT name="Events">
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 0.125C0.964466 0.125 0.125 0.964467 0.125 2V14C0.125 15.0355 0.964467 15.875 2 15.875H18C19.0355 15.875 19.875 15.0355 19.875 14V2C19.875 0.964466 19.0355 0.125 18 0.125H2ZM1.875 2C1.875 1.93096 1.93096 1.875 2 1.875H18C18.069 1.875 18.125 1.93096 18.125 2V10.7852L14.7646 7.36734L12.1176 10.0596L7.88227 5.75195L1.875 11.8619V2ZM2.10411 14.125H18C18.069 14.125 18.125 14.069 18.125 14V13.2813L14.7646 9.86345L12.1176 12.5558L7.88227 8.24807L2.10411 14.125Z"
              fill="#A2AAC1"
            />
          </svg>
          {props.events}
        </DDDT>
      </dl>
    </div>
  );
}

export default Panel;
