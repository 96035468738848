import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { HOST, SOCKET_URL, MAPBOX_API_ACCESS_TOKEN } from 'config/common';
import stateCodes from 'config/stateCodes';

import { Video } from 'components';
import { HLSDetails } from "components/@HHWall";
import './styles.css';

const HHWall = () => {
  const params = useParams();
  const { approved } = params;

  const [addresses, setAddresses] = useState({});
  const [nicenames, setNicenames] = useState({});
  const [chasers, setChasers] = useState({});
  const [approvedChasers, setApprovedChasers] = useState([]);

  useEffect(() => {
    // TO: abstract away to share between HHMap and HHWall
    const socket = new WebSocket(SOCKET_URL);

    socket.onmessage = (data) => {
      const chasers = JSON.parse(data.data);
      setChasers(chasers);
    };

    return function cleanup() {
      socket.close();
    };
  }, []);

  useEffect( () => {
    // TODO: move to https://docs.mapbox.com/api/search/#batch-geocoding batch for multi chasers
    // TODO: duped from Livestreams.js
    async function fetchAddresses() {
      let newAddresses = { ...addresses };
      for (let c of Object.keys(chasers)) {
        if (
          !newAddresses.hasOwnProperty(c) &&
          chasers[c].hasOwnProperty('location')
        ) {
          newAddresses[c] = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${chasers[c].location.lng},${chasers[c].location.lat}.json?types=place&access_token=${MAPBOX_API_ACCESS_TOKEN}`
          )
            .then((r) => r.json())
            .then((d) => {
              let place = d.features[0]?.place_name.split(', ').slice(0, 2);
              let state = stateCodes[place[1]];
              if (!state) state = place[1];
              return `${place[0]}, ${state}`;
            });
        }
      }
      setAddresses(newAddresses);
    }
    async function fetchNicenames() {
      let newNicenames = { ...nicenames };
      for (let c of Object.keys(chasers)) {
        if (
          !newNicenames.hasOwnProperty(c) &&
          chasers[c].hasOwnProperty('location')
        ) {
          newNicenames[c] = await fetch(`${HOST}/wp-json/wp/v2/chasers/${c}`)
            .then((r) => r.json())
            .then((u) => {
              return u.slug;
            });
        }
      }
      setNicenames(newNicenames);
    }
    fetchAddresses();
    fetchNicenames();
  }, [chasers]); // + addresses, nicenames

  useEffect(() => {
    if (approved)
      setApprovedChasers(approved.split(',').map((c) => parseInt(c)));
  }, [approved]);

  return (
    <div className="HHWall">
      {Object.keys(chasers)
        .filter((id) => {
          return approvedChasers.includes(parseInt(id));
        })
        .map((id) => {
          const chaser = chasers[id];
          return (
            <article className="Wall__video">
              <h2>
                {chaser.name}
                <span>{addresses[id]}</span>
              </h2>
              {chaser.live ? (
                <figure>
                  <Video playback_id={chaser.live} />
                </figure>
              ) : (
                <figure>
                  <figcaption>
                    <em>Not Streaming</em>
                  </figcaption>
                </figure>
              )}
              {chaser.live && <HLSDetails nicename={nicenames[id]} />}
            </article>
          );
        })}
    </div>
  );
}

export default HHWall;
