import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Marker } from 'react-map-gl';
import classNames from 'classnames';

import { useQuery } from "hooks";

import { Update, Avatar } from 'components';
import ChasePath from "./ChasePath";

const Chaser = ({
                  id,
                  name,
                  avatar_url,
                  updates,
                  live,
                  handleFlyToChaser,
                  location
                }) => {
  const params = useParams();
  const { station } = params;

  const { userID, isTrack, areEvents } = useQuery();
  const showTrack = isTrack && !station;
  const showEvents = areEvents && !station;

  const [route, setRoute] = useState(false);

  useEffect(() => {
    if (route && Array.isArray(route))
      setRoute([...route, ...[[location.lng, location.lat]]]);
  }, [location]);

  useEffect(() => {
    const prevRouteUrl = `${process.env.REACT_APP_WS}/route/${id}`;
    fetch(prevRouteUrl)
      .then((r) => r.json())
      .then((path) => setRoute(path));
  }, []);

  return (
    <div className={classNames('chaser', {
      'tracking': userID === id
    })}>
      <Marker
        latitude={location.lat}
        longitude={location.lng}
        offsetTop={0}
        offsetLeft={0}
        className="ChaserMarker"
        onClick={() => handleFlyToChaser(id)}
      >
        <div className={classNames('ChaserPin', {
            'ChaserPin--live': live,
            'ChaserPin--playing': userID === id
          })}
        >
          <div className="Avatar">
            <Avatar name={name} avatarUrl={avatar_url}  />
          </div>
          {live ? <span>Live</span> : ''}
        </div>
      </Marker>
      {showTrack && <ChasePath id={id} route={route} />}
      {showEvents && updates.length > 0 && updates.map(update =>
        <Update
          key={update.update.id}
          chaserID={id}
          chaserName={name.replace(' ', '')}
          {...update}
        />
      )}
    </div>
  );
}
export default Chaser;
