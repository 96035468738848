export const stations = {
  'kake': {
    link: 'https://www.kake.com/',
    imgSrc: '/assets/kake.png',
    imgAlt: 'KAKE',
    imgHeight: 20,
  },
  'kalb': {
    link: 'https://www.kalb.com/',
    imgSrc: '/assets/kalb.svg',
    imgAlt: 'KALB',
    imgHeight: 28,
  },
  'storm-team-8': {
    link: '#',
    imgSrc: '/assets/storm-team-8.png',
    imgAlt: 'Storm Team 8',
    imgHeight: 28,
  }
}
