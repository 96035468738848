import React from 'react';
import { WebMercatorViewport } from "react-map-gl";

import { useChasersContext, useChatDrawerContext, useMapContext } from "hooks";

import { BoundsSVG } from "assets/icons";
import './styles.scss';

const BoundingChasers = () => {
  const { viewport, setViewport, bounds, setBounds } = useMapContext();
  const { chasers } = useChasersContext();
  const { chatOpen } = useChatDrawerContext();

  function handleSetToBounds() {
    const coords = Object.values(chasers).reduce((locs, c) => {
      return {
        lats: [c.location.lat, ...locs.lats], lons: [c.location.lng, ...locs.lons],
      };
    }, {
      lats: [], lons: [],
    });
    let bounds = [[Math.min(...coords.lons), Math.min(...coords.lats)], [Math.max(...coords.lons), Math.max(...coords.lats)],];

    try {
      let newViewport = new WebMercatorViewport(viewport).fitBounds(bounds, {
        padding: 50, offset: [250, 0],
      });
      setViewport(newViewport);
      setBounds(bounds);
    } catch (e) {
      console.log(e);
    }
  }

  if (!(Object.keys(chasers).length > 1)) return null;

  return (
    <div className={`bounds ${bounds && 'bounds--set'} ${!chatOpen && 'collapsed'}`}>
      <button
        onClick={handleSetToBounds}
        disabled={Object.keys(chasers).length < 2}
      >
        <BoundsSVG/>
      </button>
    </div>
  );
}

export default BoundingChasers;
