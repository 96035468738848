import { UnionSVG } from 'assets/icons';

import '../styles/CustomSystemMessage.scss';

export const CustomSystemMessage = ({ message }) => {
  return (
    <>
      <div className="message-wrapper">
        <UnionSVG className="icon" />
        <div>
          <div dangerouslySetInnerHTML={{ __html: message.html }}></div>
        </div>
      </div>
    </>
  );
};
