import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";

import { HOST, MAP_MAX_ZOOM } from "config/common";
import { useChasersContext, useMapContext, useQuery } from "hooks";

import { Video, Avatar } from 'components';
import { LeftArrowSVG, RightArrowSVG, XSVG } from "assets/icons";

const FetchableUpdatePanel = () => {
  const history = useHistory();
  const { isMobile, userID, userName, updateID } = useQuery();
  const { chasers } = useChasersContext();
  const {
    viewport, setViewport,
    streamingChaserID, setStreamingChaserID,
    shouldCenterUpdate, setShouldCenterUpdate
  } = useMapContext();

  const [prevUpdate, setPrevUpdate] = useState(false);
  const [nextUpdate, setNextUpdate] = useState(false);
  const [currentUpdate, setCurrentUpdate] = useState(false);

  const chaser = chasers[userID];
  const updates = chasers[userID]?.updates;

  useEffect(() => {
    if (updates) {
      const newUpdate = updates.find(({ update }) => update.id.toString() === updateID);
      if (newUpdate?.update?.type && newUpdate?.update?.id && newUpdate?.update?.id !== currentUpdate?.id) {
        fetchUpdate(newUpdate.update.type, newUpdate.update.id);
      }
    }
    if (!updateID && currentUpdate) {
      setCurrentUpdate(false);
      setPrevUpdate(false);
      setNextUpdate(false);
    }
  }, [updates, updateID]);

  useEffect(() => {
    if (updates) {
      const index =
        updates &&
        updates.findIndex((u) => u.update.id === currentUpdate.id);

      if (index > -1) {
        setNextUpdate(
          (() => {
            if (index === updates.length - 1) return false;
            return updates[index + 1];
          })()
        );
        setPrevUpdate(
          (() => {
            if (index === 0) return false;
            return updates[index - 1];
          })()
        );
      }
    }
  }, [currentUpdate.id, updates]);

  useEffect(async () => {
    if (currentUpdate && shouldCenterUpdate) {
      await setViewport({
        ...viewport,
        zoom: Math.max(viewport.zoom, MAP_MAX_ZOOM),
        longitude: currentUpdate?.meta?.chase_longitude,
        latitude: currentUpdate?.meta?.chase_latitude,
      });
      setShouldCenterUpdate(false);
    }
  }, [currentUpdate, shouldCenterUpdate]);

  useEffect(() => {
    return () => {
      setCurrentUpdate(false);
      setPrevUpdate(false);
      setNextUpdate(false);
    }
  }, []);

  const fetchUpdate = useCallback( (type, id) => {
    if (shouldCenterUpdate && currentUpdate) {
      setShouldCenterUpdate(false);
    }
    if (type === 'image' || type === 'attachment') type = 'media';
    const url = `${HOST}/wp-json/wp/v2/${type}/${id}`;

    fetch(url)
      .then((r) => r.json())
      .then(async (data) => {
        await setCurrentUpdate(data);
        history.push(`/?${isMobile ? 'mobile=true&' : ''}user_id=${userID}&user_name=${userName.replace(' ', '')}&update=${id}`, {userId: userID});
      });
  }, [isMobile, userID, userName, shouldCenterUpdate]);

  function thumbnail(update) {
    if (update.type === 'video') return update.meta?.preview;
    if (
      (update.type === 'image' ||
        update.type === 'media' ||
        update.type === 'attachment') &&
      update.media_type !== 'file'
    ) {
      if (update.media_details?.sizes?.small) {
        // TODO: duplicate here. Look for other instances of source_url
        return update.media_details.sizes.small.source_url;
      } else {
        return update.source_url;
      }
    }
  }

  const switchOnLiveStream = () => {
    setStreamingChaserID(userID);
    history.push(`/?${isMobile ? 'mobile=true&' : ''}`);
    setCurrentUpdate(false);
  }

  const closePanel = () => {
    setCurrentUpdate(false);
    history.push(`/?${isMobile ? 'mobile=true&' : ''}`);
  }

  if (!currentUpdate || streamingChaserID) return null;

  return <div className="UpdatePanel">
    <div className={`Update__meta Update__meta--${currentUpdate.type} Update__media`}>
      <div className="Update__controls">
        <div>
          <button
            disabled={!prevUpdate}
            onClick={() => fetchUpdate(prevUpdate.update.type, prevUpdate.update.id)}
          >
            <LeftArrowSVG/>
          </button>
          <button
            disabled={!nextUpdate}
            onClick={() => fetchUpdate(nextUpdate.update.type, nextUpdate.update.id) }
          >
            <RightArrowSVG/>
          </button>
        </div>
        {chaser?.live && (
          <a className="Update__golive" onClick={switchOnLiveStream}>
            Watch Live Stream
          </a>
        )}
        <button onClick={closePanel}><XSVG/></button>
      </div>
      <div className="Update__content">
        <div className="Update__chaser">
          <figure className="Update__chaser-avatar">
            {chaser && <Avatar name={chaser.name} avatarUrl={chaser.avatar_url}  />}
          </figure>
          <div className="Update__chaser-capture">
            <p>
              <strong>{chaser?.name}</strong>
            </p>
            <p>
              {currentUpdate.meta?.chase_address} ·{' '}
              {currentUpdate.date_gmt &&
                new Date(currentUpdate.date_gmt + '.000+00:00')
                  .toISOString()
                  .substr(11, 5)}{' '}
              Z
            </p>
          </div>
        </div>
        <figure className="media-wrapper">
          {(currentUpdate.type === 'media' || currentUpdate.type === 'attachment') && (
            <img src={thumbnail(currentUpdate)} alt=""/>
          )}
          {currentUpdate.type === 'video' && currentUpdate.meta && (
            <Video
              url={currentUpdate.meta?.url}
              video_id={currentUpdate.id}
              video_title={`${chaser?.name} Update`}
              isLivestream={false}
            />
          )}
          {currentUpdate.caption ? (
            <figcaption
              dangerouslySetInnerHTML={{
                __html: currentUpdate.caption.rendered,
              }}
              className="Update__text"
            />
          ) : (
            currentUpdate.meta?.caption && (
              <figcaption className="Update__text">
                {currentUpdate.meta?.caption}
              </figcaption>
            )
          )}
          {currentUpdate.type === 'text' && currentUpdate.content && (
            <figcaption
              dangerouslySetInnerHTML={{
                __html: currentUpdate.content.rendered,
              }}
              className="Update__text"
            />
          )}
        </figure>
      </div>
    </div>
  </div>;
}

export default FetchableUpdatePanel;
