import { useMessageContext } from 'stream-chat-react';

export const MessagDeleted = (props) => {
  const { message } = useMessageContext();
  return (
    <div className="str-chat__message str-chat__message--other">
      <div className="str-chat__avatar str-chat__avatar--circle str-chat__message-sender-avatar">
        <img className="str-chat__avatar-image" alt='' src={message.user.image} width={24} height={24} />
      </div>
      <div className="str-chat__message-inner">
        <div className="str-chat__message-bubble">
          <div className="str-chat__message-text delete-text">Message deleted...</div>
        </div>
      </div>
      <div className="str-chat__message-data str-chat__message-simple-data str-chat__message-metadata">
        <span className="str-chat__message-simple-name">{message.user.name}</span>
      </div>
    </div>
  );
};
