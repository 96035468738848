import React, { useRef } from 'react';
import ReactMapGL, { NavigationControl } from 'react-map-gl';

import { MAPBOX_API_ACCESS_TOKEN, MAP_MAX_ZOOM } from 'config/common';
import { useChasersContext, useMapContext } from "hooks";

import { ChasersList } from "components/@HHMap";
import BoundingChasers from "./BoundingChasers";
import WeatherLayer from "./WeatherLayer";
import TimePanel from "./TimePanel";

const Map = ({ flyToChaser }) => {
  const { viewport, setViewport, bounds, setBounds, setTrackingChaserID } = useMapContext();
  const { chasers } = useChasersContext();

  const handleFlyToChaser = id => {
    flyToChaser(id);
    setBounds(false);
  }

  const mapRef = useRef(null);

  const untrackChaser = () => {
    setTrackingChaserID(null);
    if (!bounds) {
      setBounds(false);
    }
  }

  return <ReactMapGL
    {...viewport}
    ref={mapRef}
    maxZoom={MAP_MAX_ZOOM}
    width="100vw"
    height="100vh"
    attributionControl={false}
    //transitionInterruption={TRANSITION_EVENTS.UPDATE}
    mapboxApiAccessToken={MAPBOX_API_ACCESS_TOKEN}
    mapStyle="mapbox://styles/highwaysandhailstones/ckfa12whj308819lnsd9h5y0u"
    onViewportChange={nextViewport => setViewport(nextViewport)}
    onClick={untrackChaser}
    onInteractionStateChange={ interactionState => {
      if (interactionState.isDragging || interactionState.isZooming || interactionState.isRotating) {
        untrackChaser();
      }
    }}
  >
    <ChasersList handleFlyToChaser={handleFlyToChaser} />
    <WeatherLayer mapRef={mapRef} />
    <TimePanel />
    <BoundingChasers />
    <NavigationControl
      showCompass={false}
      style={{ right: 16, bottom: Object.keys(chasers).length > 1 ? 94 : 16 }}
    />
  </ReactMapGL>;
}

export default Map;
