import React from 'react';
import classNames from "classnames";

import { useChasersContext, useMapContext, useQuery } from "hooks";

import { Video } from "components";
import { CloseSVG } from "assets/icons";

const LiveStreamPanel = ({ children, liveReplay }) => {
  const { updateID} = useQuery();
  const { streamingChaserID, setStreamingChaserID } = useMapContext();
  const { chasers } = useChasersContext();

  const closeLiveStreaming = () => setStreamingChaserID(null);

  if (!(streamingChaserID && chasers && chasers[streamingChaserID]?.live) || updateID) return null;

  return (
    <div className={classNames('UpdatePanel LiveStreamPanel', { 'replay': liveReplay })}>
      <div className="Update__meta">
        <button className="Update__close" onClick={closeLiveStreaming}>
          <CloseSVG/>
        </button>
        <div>
          <figure>{
              children ||
              <Video
                key={chasers[streamingChaserID].live}
                playback_id={chasers[streamingChaserID].live}
                video_id={chasers[streamingChaserID].live}
                video_title={`${chasers[streamingChaserID].name} Livestream`}
                isLivestream={streamingChaserID}
              />
            }
          </figure>
        </div>
      </div>
    </div>
  );
}

export default LiveStreamPanel;
