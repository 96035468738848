import { useDrawerContext } from 'hooks';

const useDrawer = () => {
  const context = useDrawerContext();

  return {
    toggleDrawer: () => {
      context.setOpen(!context.open);
      localStorage.setItem('isHHSidebarOpened', JSON.stringify(!context.open));
    },
  };
};

export default useDrawer;
